<template>
  <app-modal-payout-detail
    :show="showModalPayoutDetail"
    @close="showModalPayoutDetail = false"
    :payout="selectPayout"
    @update:payout="selectPayout = $event"
    processedPaymentLinkRoute="balance.transaction.payment-link"
  />
  <app-modal-payment-link-detail
    :show="showModalPaymentLinkDetail"
    @close="showModalPaymentLinkDetail = false"
    :payment_link="selectPaymentLink"
    @update:payment_link="selectPaymentLink = $event"
  />
  <div>
    <div class="px-4 border-b pt-10 pb-4">
      <div>
        <p class="font-medium">{{ $t("wallet.overview.title") }}</p>
      </div>
    </div>
  </div>
  <div class="grid grid-rows-1 md:grid-cols-4 gap-3 my-10 text-gray-600">
    <button
      class="shadow p-7 bg-white rounded focus:outline-none focus:ring focus:ring-primary text-left"
      :class="[
        {
          'ring ring-primary': isPaymentLink,
        },
      ]"
      @click="fetchListPaymentLink()"
    >
      <p class="text-md font-medium">
        {{ $t("wallet.overview.floating_amount") }}
      </p>
      <paragraph-shimmer
        :is-loading="balanceOverviewLoading"
        :lines="1"
        :random-size="true"
      />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
          $formats.currency(
            balanceOverview.currency,
            balanceOverview.total_payment_paid
          )
        }}
      </p>
    </button>
    <button
      class="shadow p-7 bg-white rounded focus:outline-none focus:ring focus:ring-primary text-left"
      :class="[
        {
          'ring ring-primary': payoutStatus == PAYOUT_STATUS.REQUESTED,
        },
      ]"
      @click="fetchListPayout(PAYOUT_STATUS.REQUESTED)"
    >
      <p class="text-md font-medium">
        {{ $t("wallet.overview.expected_payout") }}
      </p>
      <paragraph-shimmer
        :is-loading="balanceOverviewLoading"
        :lines="1"
        :random-size="true"
      />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
          $formats.currency(
            balanceOverview.currency,
            balanceOverview.total_payout_requested
          )
        }}
      </p>
    </button>
    <button
      class="shadow p-7 bg-white rounded focus:outline-none focus:ring focus:ring-primary text-left"
      :class="[
        {
          'ring ring-primary': payoutStatus == PAYOUT_STATUS.PROCESSING,
        },
      ]"
      @click="fetchListPayout(PAYOUT_STATUS.PROCESSING)"
    >
      <p class="text-md font-medium">
        {{ $t("wallet.overview.otw_bank") }}
      </p>
      <paragraph-shimmer
        :is-loading="balanceOverviewLoading"
        :lines="1"
        :random-size="true"
      />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
          $formats.currency(
            balanceOverview.currency,
            balanceOverview.total_payout_processing
          )
        }}
      </p>
    </button>
    <button
      class="shadow p-7 bg-white rounded focus:outline-none focus:ring focus:ring-primary text-left"
      :class="[
        {
          'ring ring-primary': payoutStatus == PAYOUT_STATUS.APPROVED,
        },
      ]"
      @click="fetchListPayout(PAYOUT_STATUS.APPROVED)"
    >
      <p class="text-md font-medium">
        {{ $t("wallet.overview.on_the_way_bank") }}
      </p>
      <paragraph-shimmer
        :is-loading="balanceOverviewLoading"
        :lines="1"
        :random-size="true"
      />
      <p class="text-2xl" v-if="!balanceOverviewLoading">
        {{
          $formats.currency(
            balanceOverview.currency,
            balanceOverview.total_payout_approved
          )
        }}
      </p>
    </button>
  </div>
  <app-table
    :loading="isPaymentLink ? paymentLinkLoading : payoutLoading"
    :apiResponse="
      isPaymentLink
        ? apiPaginationPaymentLink.data
        : apiPaginationPayout?.data ?? []
    "
    @params-changed="paramsChanged"
    :showEmptyButton="false"
    :filters="
      isPaymentLink
        ? [
            {
              key: 'filter[title]',
              type: 'text',
              placeholder: $t('payment.link.filter.title'),
              value: '',
            },
            {
              key: 'filter[email]',
              type: 'text',
              placeholder: $t('payment.link.filter.email'),
              value: '',
            },
            {
              key: 'filter[currency]',
              type: 'select',
              placeholder: $t('payment.link.filter.currency'),
              value: '',
              items: [
                {
                  id: 'MYR',
                  name: 'MYR',
                },
                {
                  id: 'SGD',
                  name: 'SGD',
                },
                {
                  id: 'BAHT',
                  name: 'BAHT',
                },
                {
                  id: 'USD',
                  name: 'USD',
                },
                {
                  id: 'IDR',
                  name: 'IDR',
                },
              ],
            },
            {
              key: 'filter[amount]',
              type: 'number',
              placeholder: $t('payment.link.filter.amount'),
              value: '',
            },
            {
              key: 'filter[payment_link_id]',
              type: 'text',
              placeholder: $t('payment.link.filter.payment_link_id'),
              value: '',
            },
            //   {
            //     key: 'filter[attempt_id]',
            //     type: 'text',
            //     placeholder: $t('payment.link.filter.payment_attempt_id'),
            //     value: '',
            //   },
            //   {
            //     key: 'filter[settlement_id]',
            //     type: 'text',
            //     placeholder: $t('payment.link.filter.settlement_id'),
            //     value: '',
            //   },
            {
              key: 'filter[created_at_between]',
              type: 'date',
              placeholder: $t('payment.link.filter.date_created_at'),
              value: '',
            },
          ]
        : [
            // {
            // key: 'filter[_id]',
            // type: 'text',
            // placeholder: $t('wallet.payout.id'),
            // value: '',
            // }
            {
              key: 'filter[amount]',
              type: 'text',
              placeholder: $t('wallet.payout.amount'),
              value: '',
            },
            {
              key: 'filter[created_at_between]',
              type: 'date',
              placeholder: $t('wallet.payout.date'),
              value: '',
            },
          ]
    "
    @on-item-click="onItemClick"
  >
    <template v-if="isPaymentLink" v-slot:header>
      <th class="font-bold">{{ $t("wallet.overview.header.title") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.amount") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.charges") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.net_amount") }}</th>
      <th class="font-bold">{{ $t("payment.link.header.status") }}</th>
      <th class="font-bold">{{ $t("payment.link.header.system_status") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.email") }}</th>
      <th class="font-bold">{{ $t("wallet.overview.header.date") }}</th>
    </template>

    <!--table for payout status-->
    <template v-else v-slot:header>
      <td>
        <b>{{ $t("wallet.payout.bank_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.account_no") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.date") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.amount") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.status") }}</b>
      </td>
    </template>

    <!--data for table body: Floating amount-->
    <template v-if="isPaymentLink" v-slot:body="slotData">
      <td>
        {{ slotData.model.title }}
      </td>
      <td>
        <p class="font-medium">
          {{
            $formats.currency(
              slotData.model.currency,
              slotData.model.amount ?? 0.0
            )
          }}
        </p>
      </td>
      <td>
        <p class="font-medium">
          {{
            $formats.currency(
              slotData.model.currency,
              getPaymentAmount(slotData.model.payments ?? []).charges
            )
          }}
        </p>
      </td>
      <td>
        <p class="font-medium">
          {{
            $formats.currency(
              slotData.model.currency,
              getPaymentAmount(slotData.model.payments ?? []).net_amount
            )
          }}
        </p>
      </td>
      <td class="flex place-items-center space-x-2 justify-between">
        <app-badge :status="'success'" class="flex space-x-1 text-xs">
          <p>
            {{ $t("wallet.overview.succeded") }}
          </p>
        </app-badge>
      </td>
      <td>
        <app-badge
          v-if="slotData.model.settlement != null"
          :status="'success'"
          class="w-16 text-xs"
        >
          {{ $t("payment.detail.processed") }}
        </app-badge>
        <p v-else>-</p>
      </td>
      <td>
        {{ slotData.model.email }}
      </td>
      <td>
        {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
    </template>

    <!--data for table body: Payout-->
    <template v-else v-slot:body="data">
      <td>
        {{ data.model.business.bank?.bank.display_name }}
      </td>
      <td>
        {{ data.model.business.bank?.account_no }}
      </td>
      <td>
        {{ $moment(data.model.updated_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td>
        {{ $formats.currency(data.model.currency, data.model.amount ?? 0.0) }}
      </td>
      <td>
        <app-badge
          class="capitalize w-24"
          :status="getStatus(data.model?.status)"
        >
          {{ data.model?.status ?? "-" }}
        </app-badge>
      </td>
    </template>

    <!--footer for floating amount: Total-->
    <template v-if="isPaymentLink" v-slot:footer>
      <tr>
        <td class="font-bold">{{ $t("wallet.transaction.total") }}</td>
        <td />
        <td class="font-bold">
          {{
            $formats.currency(
              apiPaginationPaymentLink?.data?.summary?.currency,
              apiPaginationPaymentLink?.data?.summary?.amount ?? 0
            )
          }}
        </td>
        <td class="font-bold">
          {{
            $formats.currency(
              apiPaginationPaymentLink?.data?.summary?.currency,
              apiPaginationPaymentLink?.data?.summary?.charge ?? 0
            )
          }}
        </td>
        <td class="font-bold" colspan="7">
          {{
            $formats.currency(
              apiPaginationPaymentLink?.data?.summary?.currency,
              apiPaginationPaymentLink?.data?.summary?.net ?? 0
            )
          }}
        </td>
      </tr>
    </template>

    <!--footer for payout: Total-->
    <template v-else v-slot:footer>
      <tr>
        <td class="font-bold" colspan="3">
          {{ $t("wallet.transaction.total") }}
        </td>
        <td />
        <td class="font-bold">
          {{
            $formats.currency(
              apiPaginationPayout?.data?.summary?.currency,
              apiPaginationPayout?.data?.summary?.amount ?? 0
            )
          }}
        </td>
        <td>&nbsp;</td>
      </tr>
    </template>
  </app-table>
</template>

<script>
import PAYOUT_STATUS from "@/utils/const/payout_status";
import { ParagraphShimmer } from "vue3-shimmer";

export default {
  components: {
    ParagraphShimmer,
  },
  data() {
    return {
      queryParams: "",
      originalQueryParams: "",
      showModalPayoutDetail: false,
      selectPayout: null,
      showModalPaymentLinkDetail: false,
      selectPaymentLink: null,
      loadFilterFromUrl: false,
      isPaymentLink: true,
      payoutStatus: null,
      isPayoutRequested: false,
      PAYOUT_STATUS: PAYOUT_STATUS,
    };
  },
  computed: {
    apiPaginationPayout() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    apiPaginationPaymentLink() {
      return this.$store.getters["paymentStore/apiPaginationResponse"];
    },

    payoutLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    paymentLinkLoading() {
      return this.$store.getters["paymentStore/apiPaginationLoading"];
    },

    balanceOverviewLoading() {
      return this.$store.getters["walletStore/balanceOverviewLoading"];
    },

    balanceOverview() {
      return this.$store.getters["walletStore/balanceOverview"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },
  },

  async mounted() {
    this.fetchListPaymentLink();
    this.$store.dispatch("walletStore/retrieveBalanceOverview");
  },

  methods: {
    getStatus(status) {
      switch (status) {
        case PAYOUT_STATUS.APPROVED:
          return "success";
        case PAYOUT_STATUS.PROCESSING:
        case PAYOUT_STATUS.REQUESTED:
        case PAYOUT_STATUS.PENDING:
          return "light";
        case PAYOUT_STATUS.REJECTED:
          return "error";
        default:
          return "light";
      }
    },

    paramsChanged(filters, queryParams, status) {
      this.originalQueryParams = queryParams;
      this.queryParams = queryParams;
      if (this.fetchListPayout) {
        this.queryParams =
          this.queryParams == ""
            ? "?filter[status]=approved"
            : this.queryParams + "&filter[status]=approved";

            this.queryParams =
          this.queryParams == ""
            ? "?filter[status]=requested"
            : this.queryParams + "&filter[status]=requested";

            this.queryParams =
          this.queryParams == ""
            ? "?filter[status]=processing"
            : this.queryParams + "&filter[status]=processing";

        this.$store.dispatch(
          "walletStore/retrieveWalletPayouts",
          this.queryParams
        );
      }

      if (this.isPaymentLink) {
        this.queryParams =
          this.queryParams == ""
            ? "?filter[has_status]=paid"
            : this.queryParams + "&filter[has_status]=paid";

        this.$store.dispatch(
          "paymentStore/retrievePaymentLinks",
          this.queryParams
        );
      }
    },

    fetchListPayout(status) {
      this.isPaymentLink = false;
      this.isPayoutRequested = true;
      this.payoutStatus = status;
      if (this.payoutStatus === "requested") {
        this.queryParams = "?filter[status]=requested";
      } else if (this.payoutStatus === "processing") {
        this.queryParams = "?filter[status]=processing";
      } else if (this.payoutStatus === "approved") {
        this.queryParams = "?filter[status]=approved";
      }
      this.$store.dispatch(
        "walletStore/retrieveWalletPayouts",
        this.queryParams
      );
    },

    fetchListPaymentLink() {
      this.isPaymentLink = true;
      this.isPayoutRequested = false;
      this.payoutStatus = null;
      this.queryParams =
        this.originalQueryParams == ""
          ? "?filter[has_status]=paid"
          : this.originalQueryParams + "&filter[has_status]=paid";
      this.$store.dispatch(
        "paymentStore/retrievePaymentLinks",
        this.queryParams
      );
    },

    refresh() {
      window.location.href = this.$route.path;
    },

    onItemClick(data) {
      if (this.isPaymentLink) {
        this.showModalPaymentLinkDetail = !this.showModalPaymentLinkDetail;
        this.selectPaymentLink = data;
      } else {
        this.showModalPayoutDetail = !this.showModalPayoutDetail;
        this.selectPayout = data;
      }
    },

    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    async resendEmail(model) {
      const resendEmail = await this.$store.dispatch(
        "paymentStore/resendEmail",
        model._id
      );

      if (resendEmail == null) return;

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.resend"),
        },
        5000
      );
    },

    getPaymentAmount(payments) {
      var paymentAmount = {
        net_amount: null,
        amount: null,
        charges: null,
      };

      payments.forEach((payment) => {
        paymentAmount.net_amount += payment.settlement_amount;
        paymentAmount.amount += payment.amount;
        paymentAmount.charges +=
          (payment.amount - payment.settlement_amount) * -1;
      });

      return paymentAmount;
    },
  },
};
</script>
